import React, {Suspense, Fragment, useState, useContext} from 'react'
import { BrowserRouter as Router, Route, Link, withRouter, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'unstated'
import CartContainer from './react/CartContainer'
import Footer from './react/Footer'
import AutoMenu from './react/AutoMenu'
import AutoMenuChecker from './react/AutoMenuChecker'
import CartMinimized from './react/CartMinimized'
import CartCheckout from './react/CartCheckout'
import Product from './Cart/Product'
import ShopDataContainer from './react/ShopDataContainer'
// import StripeAsyncProvider from './StripePayment/StripeAsyncProvider'
import OrderStatus from './Checkout/OrderStatus'
import HowToInstall from './Pages/HowToInstall'
import {injectCustomComponents} from './Custom/CustomComponentsProvider'
import OrderScrollManager from './utils/OrderScrollManager'
import ErrorBoundary from './utils/ErrorBoundary'
import SiteNotice from './Notices/SiteNotice'
import Default404NotFound from './Pages/Default404NotFound'
import PlatformConvert from './Pages/PlatformConvert'
import PostAddToCartQuickNav from './Cart/PostAddToCartQuickNav'
import {Base} from './Headers'
import ThemeContext from './Context/ThemeContext'

const App = ({restId, customComponents, locationId, location, cssGlobalPrefix, basePath}) => {
  let routeCSSClass = "path_" + location.pathname.replace('/', '_')
  let mainRouteCSSClass = "mpath_" + location.pathname.split('/')[1]
  const orderAppPaths = (customComponents && customComponents.customOrderAppPaths) || ["/menu", "/order", "/"]
  const preloadMenuData = (customComponents && customComponents.preloadMenuData) || false
  const hideSiteNotice = (customComponents && customComponents.hideSiteNotice)
  const {themeName: theme} = useContext(ThemeContext)

  let cssPrefix = cssGlobalPrefix ? ` ${cssGlobalPrefix}` : ''

  return (
    <ShopDataContainer restId={restId} preloadMenuData={preloadMenuData}>
    <Base></Base>


      <div className={"site-content " + restId + " " + routeCSSClass + " " + mainRouteCSSClass + " " + theme + cssPrefix}>

        {(restId === 'gourmet88') && <div style={{
          padding: "0.3em", background: "#481a7e", fontSize: "0.8em",color: "white"
        }}>
          <a href="tel:+18188488688" style={{color: "white", textDecoration: "none"}}>tel: (818)848-8688</a>
        </div>}

        <Switch>
{/* 
          <Route component={} path="/accessibility">

          </Route> */}


          <Route path="/menu/Food" render={() => (
            <Redirect to="/menu" />
          )}/>

          {/* New PRODUCT PAGE  */}
          <Route path="/products/:productId"
            exact
            render={(props) => {
              return(
                <ErrorBoundary to="/">
                   {(customComponents && customComponents.StickyTopBar && <customComponents.StickyTopBar />)
                || <CartMinimized />}
                  <Product
                  action="new"
                  productId={props.match.params.productId}></Product>
                </ErrorBoundary>
              )}} />

          {/* Edit PRODUCT PAGE  */}
          <Route path="/cart/:cartItemId"
            exact
            render={(props) => {
              return(
              <>
               {(customComponents && customComponents.StickyTopBar && <customComponents.StickyTopBar />)
                || <CartMinimized />}
              <Product
                action="edit"
                cartItemId={props.match.params.cartItemId}></Product>
              </>
              ) }} />

          <Route path="/success/:type/:productId"
            exact
            render={(props) => {
              return(
                <PostAddToCartQuickNav
                  type={props.match.params.type}
                  productId={props.match.params.productId}
                ></PostAddToCartQuickNav>
              ) }} />

          <Route render={() => (
            <Fragment>
              {(customComponents && customComponents.StickyTopBar && <customComponents.StickyTopBar />)
                || <CartMinimized />}
              {!hideSiteNotice && <SiteNotice></SiteNotice>}
              {/* Site Navigation  */}
              {customComponents && customComponents.SiteNav &&
                <Route path="/" render={(props) => <customComponents.SiteNav
                  shopId={restId}
                />} />}

              {/* Pages */}
              <Switch>
                <Route exact path="/c/:phone" render={(props) => {
                  return(<PlatformConvert
                    shopId={restId}
                    phone={props.match.params.phone} />)
                }} />



                {/* CHECKOUT PAGE */}
                <Route exact path="/checkout"
                render={(props) => <CartCheckout shopId={restId}></CartCheckout>} />

                <Route exact path="/order-status/:orderId/:shopId?"
                  render={(props) => <OrderStatus shopId={props.match.params.shopId || restId} orderId={props.match.params.orderId} />}
                />
                {/* EVENTS PAGE */}
                {/* <Route exact path="/events" render={(props) => */}
                {/* <div className="events-page"><GoogleCalendar mini={false}></GoogleCalendar></div> }></Route> */}

                {/* Legacy Menu Page */}
                {/* <Route exact path="/menu/Food/:categoryName" component={LegacyMenuPage} /> */}

                {customComponents && customComponents.pages &&
                  customComponents.pages.map(({path, notExact, component: Component}, index) => {
                    if (!Component) { return null }
                    return(
                      <Route key={`${path}-index`} exact={!notExact} path={path} render={(props) => {
                        return(
                          <Suspense fallback={<div>Loading Page...</div>}>
                            <Component {...props}></Component>
                        </Suspense>
                        )
                      }}/>

                    )
                  })}

                  {/* <Route component= path="/alcohol-to-go"></Route> */}


                    <Route path="/app" component={HowToInstall}></Route>


              {/* HOMEPAGE*/}
              {customComponents && customComponents.Header &&
              <Route path="/" exact
                render={() => (
                  <customComponents.Header
                    shopId={restId}
                    locationId={locationId}
                    basePath={basePath}
                  />
                )}
              />}

                {/* /order  */}
                {customComponents && customComponents.OrderingHeader &&
                  <Route path="/order" exact
                    render={() => (
                      <customComponents.OrderingHeader
                        shopId={restId}
                        locationId={locationId}
                        basePath={basePath}

                      />
                    )}
                  />}

                {/* <Route path="/order" exact component={customComponents.OrderingHeader} /> */}

                  {/* Menu checker */}
              <Route path={'/menu-check'}
                exact
                render={(props) => {

                  let displayStyle = props.match.isExact ?  {} : {display: 'none'}
                  return(<div className="ordering-menu" style={displayStyle}>
                    <AutoMenuChecker
                      altStyleA
                      productFilter={customComponents && customComponents.productFilter}
                      subMenuFilter={customComponents && customComponents.subMenuFilter}
                      subMenuDisableFilter={customComponents && customComponents.subMenuDisableFilter}
                    />
                  </div>)}} />

                <Route component={Default404NotFound} />
              </Switch>

            </Fragment>
          )}/>

        </Switch>



        {/* always-there ORDER APP  */}
        <Route path={orderAppPaths}
          exact
          render={(props) => {

            let displayStyle = props.match.isExact ?  {} : {display: 'none'}
            return(<div className="ordering-menu" style={displayStyle}>
              <AutoMenu
                altStyleA
                productFilter={customComponents && customComponents.productFilter}
                subMenuFilter={customComponents && customComponents.subMenuFilter}
                subMenuDisableFilter={customComponents && customComponents.subMenuDisableFilter}
               />
            </div>)}} />



      


        <Footer></Footer>

      </div>
    </ShopDataContainer>
  )
}

export default withRouter(injectCustomComponents(App))
